.button-effects{
    margin-bottom: 40px;
}
.button-after,
.button-after .container{
	position:relative;
	z-index:2;
}
.button-after:after{
    content: "";
    position: absolute;
    right: 0;
    height: 100%;
    width: 50%;
    top: 0;
    background: #1A1A1A;
    z-index: 1;
}
.btnhover1 {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	position: relative;
}
.btnhover1:hover {
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;	
}
.btnhover2 {
	position: relative;
	overflow: hidden;
}
.btnhover2 span {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
}
.btnhover2:hover{
	background-color: var(--color-hover);
}
.btnhover2:hover > span{
	opacity: 0;
	-webkit-transform: translate(0px,-40px);
	transform: translate(0px,-40px);
}
.btnhover2::after{
	content: attr(data-text);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	-webkit-transform: translate(0, 100%);
	transform: translate(0, 100%);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnhover2:hover::after{
	opacity: 1;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
.btnhover3 {
	position: relative;
	overflow: hidden;
}
.btnhover3 span {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.btnhover3:hover{
	background-color: var(--color-hover);
}
.btnhover3:hover > span{
	opacity: 0;
	-webkit-transform: translate(0px,40px);
	transform: translate(0px,40px);
}
.btnhover3:after{
	content: attr(data-text);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	-webkit-transform: translate(-100%, 0);
	transform: translate(-100%, 0);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;	
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;	
}
.btnhover3:hover:after{
	opacity: 1;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
.btnhover4 {
	position: relative;
	overflow: hidden;
}
.btnhover4 > span {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.btnhover4:hover{
	background-color: var(--color-hover);
}
.btnhover4:hover > span{
	opacity: 0;
	-webkit-transform: translate(0px,40px);
	transform: translate(0px,40px);
}
.btnhover4::after{
	content: attr(data-text);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	-webkit-transform: translate(-30%, -50%) rotate(-30deg);
	transform: translate(-30%, -50%) rotate(-30deg);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;	
	vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnhover4:hover::after{
	opacity: 1;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none;
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none;
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
.btnhover5{
	position: relative;
}
.btnhover5:hover{
	-webkit-animation-name: jello;
	animation-name: jello;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	
}
.btnhover6{
	position: relative;	
	overflow:hidden;
	z-index: 2;		
}
.btnhover6:hover{
	-webkit-transition: all 0.8s ease 0.1s;
	-moz-transition: all 0.8s ease 0.1s;
	-o-transition: all 0.8s ease 0.1s;
	transition: all 0.8s ease 0.1s;	
}
.btnhover6::before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0;
	background-color: var(--color-hover);
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(0.5, 1);
	transform: scale(0.5, 1);
}
.btnhover6:hover::before{
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}
.btnhover7{
	position: relative;	
	overflow:hidden;
	z-index: 2;	
}
.btnhover7:hover{
	-webkit-transition: all 0.8s ease 0.1s;
	-moz-transition: all 0.8s ease 0.1s;
	-o-transition: all 0.8s ease 0.1s;
	transition: all 0.8s ease 0.1s;	
}
.btnhover7::before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0;
	background-color: var(--color-hover);
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(1, 0.5);
	transform: scale(1, 0.5);
}
.btnhover7:hover::before{
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}
.btnhover8{
	position: relative;	
	overflow:hidden;
	z-index: 2;	
}
.btnhover8:hover{
	-webkit-transition: all 0.8s ease 0.1s;
	-moz-transition: all 0.8s ease 0.1s;
	-o-transition: all 0.8s ease 0.1s;
	transition: all 0.8s ease 0.1s;	
}
.btnhover8:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 0%;
	height: 100%;
	z-index: -1;
	opacity: 0;
	background-color: var(--color-hover);
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;

}
.btnhover8:hover::before{
	opacity: 1;
	width: 100%;
}
.btnhover9{
	position: relative;	
	overflow:hidden;
	z-index: 2;	
}
.btnhover9:hover{
	-webkit-transition: all 0.5s ease 0.1s;
	-moz-transition: all 0.5s ease 0.1s;
	-o-transition: all 0.5s ease 0.1s;
	transition: all 0.5s ease 0.1s;	
}
.btnhover9::before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0;
	background-color: var(--color-hover);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}
.btnhover9:hover::before{
	opacity: 1;
	-webkit-transform: skewX(-180deg)  scale(0.5,1);
	transform: skewX(-180deg)  scale(0.5,1);
}
.btnhover10{
	position: relative;
	overflow: hidden;
	z-index:1;
}
.btnhover10::before{
	content: '';
	position: absolute;
	top: 50px;
	left: 50%;
	width: 0;
	height: 0;
	border-left: 100px solid transparent;
	border-right: 100px solid transparent;
	border-bottom: 190px solid rgba(0,0,0,0.1);
	z-index: -1;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	 -webkit-transform: translate(-50%,0);
	  -moz-transform: translate(-50%,0);
	   -ms-transform: translate(-50%,0);
	    -o-transform: translate(-50%,0);
		 transform: translate(-50%,0);
}
.btnhover10::after{
	content: '';
	position: absolute;
	top: 50px;
	left: 50%;
	width: 0;
	height: 0;
	border-left: 100px solid transparent;
	border-right: 100px solid transparent;
	border-bottom: 190px solid  rgba(0,0,0,0.1);
	z-index: -1;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-transform: translate(-50%,0);
	   -moz-transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		 -o-transform: translate(-50%,0);
			transform: translate(-50%,0);
}
.btnhover10::after{
	-webkit-transition-delay: 0.2s; /* Safari */
    transition-delay: 0.2s;
}
.btnhover10:hover::before{
	opacity: 1;
	-webkit-transform:translate(-50%,-70%);
	transform:translate(-50%,-70%);	
}
.btnhover10:hover::after{
	opacity: 1;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);	
}
.btnhover11 {
	position: relative;
	z-index:2;
}
.site-button.btnhover11.outline-2,
.site-button.btnhover11.outline{
	border-width:0;
}
.btnhover11:hover{
	-webkit-transition: all 0.5s ease 0.1s;
	-moz-transition: all 0.5s ease 0.1s;
	-o-transition: all 0.5s ease 0.1s;
	transition: all 0.5s ease 0.1s;	
}
.btnhover11::before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-transform: scale(1);
	transform: scale(1);
	border-radius: 4px;
}
.btnhover11:hover::before{
	opacity: 0;
	-webkit-transform: scale(1.2,1.2);
	transform: scale(1.2,1.2);
}
.btnhover11::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0;
	background-color: var(--color-hover);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-transform: scale(0.5,0.5);
	transform: scale(0.5,0.5);
	border-radius: 4px;
}
.site-button.btnhover11.outline.outline-2::before{
	border: 2px solid;
}
.site-button.btnhover11.outline::before{
	border: 1px solid var(--color-hover);
}
.btnhover11:hover::after{
	opacity: 1 ;
	-webkit-transform: scale(1,1);
	transform: scale(1,1);
}
.btnhover12:hover{
	-webkit-transition: all 0.5s ease 0.1s;
	-moz-transition: all 0.5s ease 0.1s;
	-o-transition: all 0.5s ease 0.1s;
	transition: all 0.5s ease 0.1s;	
}
.btnhover12 {
	position: relative;
	overflow: hidden;
	z-index:1;
}
.btnhover12::before, 
.btnhover12::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index:-1;
	background-color: rgba(0,0,0,0.5);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-transform: translate(0,-100%);
	transform: translate(0,-100%);
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.btnhover12::after{
	-webkit-transition-delay: 0.2s; /* Safari */
    transition-delay: 0.2s;
	background:var(--color-hover);
}
.btnhover12:hover::before, .btnhover12:hover::after{
	-webkit-transform: translate(0,0);
	transform: translate(0,0);
}
.btnhover13 {
	position: relative;
	overflow: hidden;
	z-index:1;
}
.btnhover13:hover{
	-webkit-transition: all 0.5s ease 0.1s;
	-moz-transition: all 0.5s ease 0.1s;
	-o-transition: all 0.5s ease 0.1s;
	transition: all 0.5s ease 0.1s;	
}
.btnhover13::before, 
.btnhover13::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    z-index: -1;
	background-color: rgba(0,0,0,0.5);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-transform: translate(-100%,0);
	transform: translate(-100%,0);
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.btnhover13::after{
	-webkit-transition-delay: 0.2s; /* Safari */
    transition-delay: 0.2s;
	background:var(--color-hover);
}
.btnhover13:hover::before, .btnhover13:hover::after{
	-webkit-transform: translate(0,0);
	transform: translate(0,0);	
}
.btnhover14 {
	position: relative;
	overflow: hidden;
	z-index:1;
}
.btnhover14::before, 
.btnhover14::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    z-index: -1;
	background-color: rgba(0,0,0,0.5);
	-webkit-transition: all 0.8s;
	-moz-transition: all 0.8s;
	-o-transition: all 0.8s;
	transition: all 0.8s;
	-webkit-transform: translate(-13%,-190%) rotate(-30deg);
	transform: translate(-13%,-190%) rotate(-30deg);
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.btnhover14::after{
	-webkit-transition-delay: 0.8s; /* Safari */
    transition-delay: 0.8s;
	background:var(--color-hover);	
}
.btnhover14:hover::before, 
.btnhover14:hover::after{
	-webkit-transform: translate(0,0);
	transform: translate(0,0);	
	-ms-transform: translate(0,0);	
	-moz-transform: translate(0,0);	
	-o-transform: translate(0,0);	
}
.btnhover15 {
	position: relative;
	overflow: hidden;
	z-index:1;	
}
.btnhover15::before, .btnhover15::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    z-index: -1;
	background-color: rgba(0,0,0,0.5);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	
	-webkit-transform: translate(13%,190%) rotate(-30deg);
	transform: translate(13%,190%) rotate(-30deg);
	-moz-transform: translate(13%,190%) rotate(-30deg);
	-o-transform: translate(13%,190%) rotate(-30deg);
	-ms-transform: translate(13%,190%) rotate(-30deg);
	
	
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.btnhover15::after{
	-webkit-transition-delay: 0.2s; /* Safari */
    transition-delay: 0.2s;
	background:var(--color-hover);		
}
.btnhover15:hover::before, .btnhover15:hover::after{
	-webkit-transform: translate(0,0);
	transform: translate(0,0);	
	-ms-transform: translate(0,0);	
	-moz-transform: translate(0,0);	
	-o-transform: translate(0,0);
}
.btnhover16 {
	position: relative;
	overflow: hidden;
	z-index:1;
}
.btnhover16::before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 1;
	-webkit-transform: translate(0,-105%);
	transform: translate(0,-105%);
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: rgba(255,255,255,1);
	background-color: rgba(255,255,255,0.5);
}
.btnhover16:hover::before{
	opacity: 0;
	-webkit-transform: translate(0,0);
	transform: translate(0,0);	
}
.btnhover17 {
	position: relative;
	overflow: hidden;
	z-index:1;
}
.btnhover17::before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 1;
	-webkit-transform: translate(-105%,0);
	transform: translate(-105%,0);
	border-right-width: 1px;
	border-right-style: solid;
	border-right-color: rgba(255,255,255,1);
	background-color: rgba(255,255,255,0.5);
}
.btnhover17:hover::before{
	opacity: 0;
	-webkit-transform: translate(0,0);
	transform: translate(0,0);	
}
.btnhover18 {
	position: relative;
	z-index:1;
	overflow: hidden;
}
.btnhover18::before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 1;
	-webkit-transform: translate(-13%,-190%) rotate(-30deg);
	transform: translate(-13%,-190%) rotate(-30deg);
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: rgba(255,255,255,1);
	background-color: rgba(255,255,255,0.5);
}
.btnhover18:hover::before{
	opacity: 0;
	-webkit-transform: translate(0,0);
	transform: translate(0,0);	
}
.btnhover19 {
	position: relative;
	z-index:1;
	overflow: hidden;
}
.btnhover19::before, .btnhover19::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	opacity: 1;
	-webkit-transform: translate(0,-105%);
	transform: translate(0,-105%);
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: rgba(255,255,255,1);
	background-color: rgba(255,255,255,0.5);
}
.btnhover19::after{
	-webkit-transition-delay: 0.2s; /* Safari */
    transition-delay: 0.2s;
}
.btnhover19:hover::before, .btnhover19:hover::after{
	opacity: 0;
	-webkit-transform: translate(0,0);
	transform: translate(0,0);	
}
.btnhover20 {
	position: relative;
	z-index:1;
	overflow: hidden;
}
.btnhover20::before, .btnhover20::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	-webkit-transition: all 0.8s;
	-moz-transition: all 0.8s;
	-o-transition: all 0.8s;
	transition: all 0.8s;
	opacity: 1;
	-webkit-transform: translate(-105%,0);
	transform: translate(-105%,0);
	border-right-width: 2px;
	border-right-style: solid;
	border-right-color: rgba(255,255,255,1);
	background-color: rgba(255,255,255,0.5);
}
.btnhover20::after{
	-webkit-transition-delay: 0.75s; /* Safari */
    transition-delay: 0.75s;
}
.btnhover20:hover::before, .btnhover20:hover::after{
	opacity: 0;
	-webkit-transform: translate(0,0);
	transform: translate(0,0);	
}
.btnhover21 {
	position: relative;
	z-index:1;
	overflow: hidden;
}
.btnhover21::before, .btnhover21::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 1;
	-webkit-transform: translate(-13%,-190%) rotate(-30deg);
	transform: translate(-13%,-190%) rotate(-30deg);
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: rgba(255,255,255,1);
	background-color: rgba(255,255,255,0.25);
}
.btnhover21::after{
	-webkit-transition-delay: 0.2s; /* Safari */
    transition-delay: 0.2s;
}
.btnhover21:hover::before, .btnhover21:hover::after{
	opacity: 0;
	-webkit-transform: translate(0,0);
	transform: translate(0,0);	
}